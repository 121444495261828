<template>

  <router-link :class="['card', desktopAuto ? 'card-desktop' : '']" :to="{ name: 'EventView', params: {id: content.id } }">

    <span class="alignment"></span>
    <div class="card-image" :style="getStyle"></div>
    <div class="card-body">
      <bookmark-button  :parentClass="'card-body-icon'" :content="content" v-if="content!==null"/>
      <h5>{{ title }}</h5>
      <div v-if="isset(nextAppointmentDate)" class="appointment-text d-flex align-items-center mb-2 mt-1">
        {{nextAppointmentWday}} {{nextAppointmentDate}}, 
        {{ nextAppointmentStartTime }} - {{nextAppointmentToTime}}
      </div>
      <h6 class="info-text" v-if="readAbleCategories!=null">{{ readAbleCategories }}</h6>
    </div>

  </router-link>
</template>

<script>
  import { bus } from '@/main'
  import { getFieldValues, getFirstFieldValue, json_decode, isset } from '@/utils/helpers';
  import { getCategoryPlaceholderImage } from '@/utils/tripmeister-helpers';
  import { getNextAppointment, isOneOfTheAppointmentsNow, getWeekday, formatTime, getMonth, getNextDateForWday, getReadableDate } from '@/utils/appointment-helpers';

  export default {
    name: 'EventCard',
    components: {
      BookmarkButton: () => import('@/components/controls/BookmarkButton'),
    },
    props: {
      item: {
        type: Object,
        required: true
      },
      desktopAuto: {
        type: Boolean,
      default: false
      },
      icon: {
        type: String,
      default: 'map',
        validator: icon => ['map', 'edit'].indexOf(icon) !== -1
      }
    },
    computed: {
      content(){
        if(this.item!=null){
          return this.item;
        }
        return null;
      },
      title(){
        if(this.content!=null){
          var title = this.content.title;
          if(this.isSmall){
            return title.length>26?title.substring(0,26)+' ...':title;
          }
          else{
            return title.length>30?title.substring(0,30)+' ...':title;                
          }
        }
        return "";
      },
      categories(){
        return getFieldValues(this.content,'kategorien');
      },
      category(){
        if(this.categories!=null && Array.isArray(this.categories) && this.categories.length>0){
          return this.categories[0];
        }
        else if(this.categories!=null && !Array.isArray(this.categories) ){
          return this.categories;
        }
        return null
      },
      readAbleCategories(){
        if(this.categories!=null && Array.isArray(this.categories) && this.categories.length>0){
          return this.categories.join(", ");
        }
        else if(this.categories!=null && !Array.isArray(this.categories) ){
          return this.categories;
        }
        return null
      },
      teaserImages(){
        var pictures = getFieldValues(this.content,'teaser-bild');
        if(pictures != null){
          if(Array.isArray(pictures)){
            return pictures;
          }
          else{
            return [pictures];
          }
        }
        return null;
      },
      teaserImage(){
        var images = this.teaserImages;
        if(images!=null){
         var image = images[Math.floor(Math.random()*images.length)];
         image = json_decode(image);
         if(typeof image === "object"){
          if(image.path.indexOf('https')!==-1){
           return image.path;
         }
         else{
           return this.$backendUrl+image.path;
         }
       }
       else{
        if(image.indexOf('https')!==-1){
          return image;
        }
        else{
          return this.$backendUrl+image;        
        }
      }
    }

      /*we'll need an alternative if there is no image*/
    return this.getCategoryPlaceholderImage(this.category);
  },
  getStyle(){
    return 'backgroundImage: url("'+this.teaserImage+'")';
  },
  appointments(){
    return getFirstFieldValue(this.item,'appointments');
  },
  nextAppointment(){
    if(this.date != null){
      return getNextAppointment(this.appointments,this.date);
    }
    return getNextAppointment(this.appointments);
  },
  nextAppointmentWday(){
    if(this.nextAppointment!=null && this.nextAppointment.wday!=null){
      var day = getWeekday(this.nextAppointment.wday);
      return day.substring(0,2) + ".";
    }
    return null;
  },
  nextAppointmentDate(){
    var date = null;
    if(this.nextAppointment!=null && this.nextAppointment.date!=null){
      date = new Date(this.nextAppointment.date);
    }
    else if(this.nextAppointment!=null && this.nextAppointment.wday!==null){
      var bla = getNextDateForWday(this.nextAppointment.wday);
      date = new Date(bla);
    }

    if(date!=null){
      return (getReadableDate(date, "normal"));
    }

    return null;
  },
  nextAppointmentStartTime(){
    if(this.nextAppointment!=null && this.nextAppointment.time!=null && this.nextAppointment.time.time_from!=null){
      var time = this.nextAppointment.time.time_from;
      return formatTime(time);
    }
    return null;
  },
  nextAppointmentToTime(){
    if(this.nextAppointment!=null && this.nextAppointment.time!=null && this.nextAppointment.time.time_to!=null){
      var time = this.nextAppointment.time.time_to;
      return formatTime(time);
    }
    return null;
  },
  nextAppointmentMonthAndYear(){
    if(this.nextAppointment!=null && this.nextAppointment.date!=null){
      var date = new Date(this.nextAppointment.date);
      var month = getMonth(date.getMonth()+1);
      var year = date.getFullYear();
      return month.substring(0,3) + '. '+year;
    }
    return null;
  },
},
methods: {
  getFieldValues,
  getCategoryPlaceholderImage,
  json_decode,
  getFirstFieldValue,
  isset,
  showReiseplanModal () {
    bus.$emit('reiseplan-modal', true)
  }
}
}
</script>

<style lang="scss" scoped>
  @import '@/scss/_variables.scss';
  @import '@/scss/_mixins.scss';

  .card {
    position: relative;
    border: none;
    box-shadow: 2px 2px 5px rgba($color: $dark-color, $alpha: 0.35);
    border-radius: 15px;
    margin-right: 10px;
    margin-bottom: 25px;
    min-width: 200px;
    width: 200px;
    &-desktop {
      width: 100%;
      @include responsive($scroll-breakpoint) {
        width: 200px;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    @include responsive($scroll-breakpoint) {
      &:first-child {
        margin-left: 15px;
      }
      &:last-child {
        margin-right: 15px;
      }
    }

    &-image {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      max-width: 100%;
      min-height: 150px;
      max-height: 150px;
      overflow: hidden;
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;

      img {
        min-width: 100%;
        height: 150px;
        width: auto;
      }
    }

    &-body {
      position: relative;
      padding: 1rem 0.5rem;
      margin-bottom: 0;
      display: flex;
      flex-direction: column;

      &-icon {
        position: absolute;
        background: rgba($color: $white-color, $alpha: 1.0);
        box-shadow: 2px 2px 5px rgba($color: $dark-color, $alpha: 0.35);
        border-radius: 50%;
        height: 50px;
        width: 50px;
        right: 8px;
        top: -25px;
        padding: 5px;
        .icon {
          text-align: center;
          border: 1px solid $dark-color;
          border-radius: 50px;
          height: 40px;
          width: 40px;
          display: inline-block;
          line-height: 36px;
          text-align: center;
          font-size: 24px;
          color: $dark-color;

          &.edit {
            border-color: transparent;
            color: $text-color;
          }
        }
      }

      h5 {
        font-size: 14px;
        font-weight: 700;
        margin-right: 50px;
        margin-bottom: 5px;
      }

      .time-text {
        font-size: 12px;
        font-weight: 400;
        color: $dark-color;
        margin-bottom: 10px;
      }

      .info-text {
        font-size: 12px;
        text-transform: uppercase;
        color: $primary;
        margin-top: auto;
      }
      .appointment-text {
        font-size: 12px;
        text-transform: uppercase;
        color: $text-color;
      }
    }
  }

</style>
